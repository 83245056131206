import changeSubaccountLabel from '@lyra/core/api/private/changeSubaccountLabel'
import fetchSubaccountIdsApi from '@lyra/core/api/private/fetchSubaccountIds'
import { MarginType } from '@lyra/core/api/types/private.get_subaccount'
import { LyraAuthHeaders } from '@lyra/core/constants/api'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { bigNumberToNumberUNSAFE } from '@lyra/core/utils/bigNumberToNumberUNSAFE'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { Address } from 'viem'

import { COLLATERAL_DUST_AMOUNT } from '../constants/bridge'
import { LoggerTransaction } from '../constants/logger'
import {
  Subaccount,
  SUBACCOUNT_MAX_LABEL_LENGTH,
  SubaccountData,
  TransferInput,
  TransferType,
} from '../constants/subaccount'
import { CollateralId, getEmptyCollaterals, TokenId } from '../constants/tokens'
import { TransferSubaccountId } from '../containers/common/TransferModal/TransferAccountDropdown'
import sleep from './sleep'
import { getActiveCollateral, getTokenDecimals, getTokenForCollateral } from './tokens'

const POLLING_INTERVAL_MS = 500

const tryFetchSubaccountIds = async (address: Address, authHeaders: LyraAuthHeaders) => {
  try {
    const {
      result: { subaccount_ids },
    } = await fetchSubaccountIdsApi({ wallet: address }, authHeaders)
    console.debug('poll subaccount_ids', subaccount_ids)
    return subaccount_ids
  } catch (error) {
    return []
  }
}

export const getCanBorrowUsdc = (subaccount: Subaccount) => {
  return (
    subaccount.positions.length > 0 ||
    subaccount.collaterals.some((collat) => collat.currency !== CollateralId.USDC)
  )
}

const getUsdcAvailableToBorrow = (subaccount: Subaccount) => {
  if (getCanBorrowUsdc(subaccount)) {
    // user has non-USDC collateral and/or open positions, use initial margin to determine usdc borrow
    return toBigNumber(subaccount.initial_margin, WEI_DECIMALS)
  } else {
    // only collateral is usdc, return usdc balance
    const usdcCollat = getSubaccountCollateral(subaccount, CollateralId.USDC)
    return usdcCollat ? toBigNumber(usdcCollat.amount, WEI_DECIMALS) : BigInt(0)
  }
}

const getAvailableCollateralBalance = (subaccount: Subaccount, collateralId: CollateralId) => {
  const subaccountCollateral = getSubaccountCollateral(subaccount, collateralId)

  // Note: USDC can have available balance with no USDC deposited
  if (collateralId === CollateralId.USDC) {
    return getUsdcAvailableToBorrow(subaccount)
  } else if (!subaccountCollateral) {
    return BigInt(0)
  } else if (+subaccountCollateral.amount < COLLATERAL_DUST_AMOUNT) {
    // Check for dust amounts to prevent unitMargin division from erroring
    return BigInt(0)
  }

  const subaccountInitialMargin = toBigNumber(subaccount.initial_margin, WEI_DECIMALS)
  const collateralBalance = toBigNumber(subaccountCollateral.amount, WEI_DECIMALS)
  // Initial margin of collateral
  const collateralInitialMargin = toBigNumber(subaccountCollateral.initial_margin, WEI_DECIMALS)

  // Calculate portion of collateral initial margin not locked by positions
  // Simplified where unitMargin = collateralInitialMargin / subaccountInitialMargin
  // And subaccountCollateralInitialMargin = subaccountInitialMargin / unitMargin
  const subaccountCollateralInitialMargin =
    collateralInitialMargin !== BigInt(0)
      ? (subaccountInitialMargin * collateralBalance) / collateralInitialMargin
      : BigInt(0)
  // Take the minimum of balance and initial margin
  const freeCollateralBalance =
    subaccountCollateralInitialMargin < collateralBalance
      ? subaccountCollateralInitialMargin
      : collateralBalance

  return freeCollateralBalance
}

export async function waitForNewSubaccountId(
  address: Address,
  subaccountIds: number[],
  authHeaders: LyraAuthHeaders,
  pollMs = 5_000 // 5 seconds
): Promise<number | null> {
  if (pollMs <= 0) {
    return null
  }
  const newSubaccountIds = await tryFetchSubaccountIds(address, authHeaders)
  // no new subaccounts, refetch
  if (newSubaccountIds.length <= subaccountIds.length) {
    await sleep(POLLING_INTERVAL_MS)
    return waitForNewSubaccountId(address, subaccountIds, authHeaders, pollMs - POLLING_INTERVAL_MS)
  } else {
    const newSubaccountId = Math.max(...newSubaccountIds) // largest subaccount ID
    console.debug('found newSubaccountId', newSubaccountId, {
      prevSubaccountIds: subaccountIds,
      newSubaccountIds,
    })
    if (!newSubaccountId) {
      console.warn('failed to find subaccount ID in orderbook')
      return null
    }
    return newSubaccountId
  }
}

export const getSubaccountBorrowAmount = (
  subaccountData: SubaccountData,
  amount: bigint,
  transferType: 'transfer' | 'withdraw'
) => {
  if (transferType === 'transfer') {
    const { balanceBn } = subaccountData.collateralBalances[CollateralId.USDC]
    return Math.max(0, bigNumberToNumberUNSAFE(amount - balanceBn, WEI_DECIMALS))
  } else {
    const { depositedBalanceBn } = subaccountData.collateralBalances[CollateralId.USDC]
    const decimals = getTokenDecimals(TokenId.USDC)
    return Math.max(0, bigNumberToNumberUNSAFE(amount - depositedBalanceBn, decimals))
  }
}

const getSubaccountMarginUtilization = (subaccount: Subaccount) => {
  // Accounts for all perp profit / loss that is not yet settled
  const adjustedPerpsValue = subaccount.positions
    .filter((pos) => pos.instrument_type === 'perp')
    .reduce((sum, pos) => {
      return sum + +pos.mark_value
    }, 0)

  let positionMaintenanceMargin = +subaccount.positions_maintenance_margin
  let collateralMaintenanceMargin = +subaccount.collaterals_maintenance_margin

  // Position margin and collateral margin will adjust once perps settle
  positionMaintenanceMargin -= adjustedPerpsValue
  collateralMaintenanceMargin += adjustedPerpsValue

  // Calculate the cash balance user will have once perps settle
  const usdcCollat = getSubaccountCollateral(subaccount, CollateralId.USDC)
  const usdcBalance = usdcCollat ? +usdcCollat.amount : 0
  const adjustedCashBalance = usdcBalance + adjustedPerpsValue

  // If the user ends up with a negative USDC balance, remove from denominator and add to numerator
  if (adjustedCashBalance < 0) {
    collateralMaintenanceMargin -= adjustedCashBalance
    positionMaintenanceMargin += adjustedCashBalance
  }

  if (positionMaintenanceMargin > 0) {
    return 0
  } else {
    return -positionMaintenanceMargin / collateralMaintenanceMargin
  }
}

export const getSubaccountData = (
  subaccount: Subaccount,
  subaccountIds: number[]
): SubaccountData => {
  const adjustedPerpsValue = subaccount.positions
    .filter((pos) => pos.instrument_type === 'perp')
    .reduce((sum, pos) => {
      return sum + +pos.mark_value
    }, 0)
  const collateralValue = +subaccount.collaterals_value + adjustedPerpsValue

  const marginUtilization = getSubaccountMarginUtilization(subaccount)

  const subaccountValue = +subaccount.subaccount_value

  /**
   * Open orders margin is equivalent to "additional initial margin required if order is filled"
   * Also only applicable in calcs that increase account risk
   * i.e. only ever negatively impacts "Buying Power" in UI
   */
  const buyingPower = Math.max(
    0,
    +subaccount.initial_margin + Math.min(0, +subaccount.open_orders_margin)
  )

  const collateralBalances = getActiveCollateral().reduce(
    (dict, collateralId) => {
      const transferrableBalanceBn = getAvailableCollateralBalance(subaccount, collateralId) // 18dp
      const transferrableBalance = bigNumberToNumberUNSAFE(transferrableBalanceBn, WEI_DECIMALS)

      const tokenDecimals = getTokenDecimals(getTokenForCollateral(collateralId))
      const withdrawableBalanceBn = toBigNumber(
        bigNumberToString(transferrableBalanceBn, WEI_DECIMALS),
        tokenDecimals
      )
      const withdrawableBalance = bigNumberToNumberUNSAFE(withdrawableBalanceBn, tokenDecimals)

      const subaccountCollateral = getSubaccountCollateral(subaccount, collateralId)

      let balanceBn = subaccountCollateral
        ? toBigNumber(subaccountCollateral.amount, WEI_DECIMALS)
        : BigInt(0)
      if (balanceBn < BigInt(0)) {
        // ignore negative cash for usdc
        balanceBn = BigInt(0)
      }

      const depositedBalanceBn = toBigNumber(
        bigNumberToString(balanceBn, WEI_DECIMALS),
        tokenDecimals
      )
      const depositedBalance = bigNumberToNumberUNSAFE(balanceBn, tokenDecimals)

      const balance = bigNumberToNumberUNSAFE(balanceBn, WEI_DECIMALS)

      const lockedBalanceBn =
        collateralId !== CollateralId.USDC ? balanceBn - transferrableBalanceBn : BigInt(0)
      const lockedBalance = bigNumberToNumberUNSAFE(lockedBalanceBn, WEI_DECIMALS)

      return {
        ...dict,
        [collateralId]: {
          balanceBn,
          balance,
          depositedBalanceBn,
          depositedBalance,
          withdrawableBalanceBn,
          withdrawableBalance,
          transferrableBalanceBn,
          transferrableBalance,
          lockedBalanceBn,
          lockedBalance,
        },
      }
    },
    getEmptyCollaterals({
      balanceBn: BigInt(0),
      balance: 0,
      depositedBalanceBn: BigInt(0),
      depositedBalance: 0,
      withdrawableBalanceBn: BigInt(0),
      withdrawableBalance: 0,
      transferrableBalanceBn: BigInt(0),
      transferrableBalance: 0,
      lockedBalanceBn: BigInt(0),
      lockedBalance: 0,
    })
  )

  // note: if not found, will render "Account 0"
  const subaccountNumber =
    subaccountIds.sort().findIndex((id) => id === subaccount.subaccount_id) + 1

  const label = subaccount.label
    ? subaccount.label.substring(0, SUBACCOUNT_MAX_LABEL_LENGTH)
    : formatSubaccountDefaultLabel(subaccountNumber)

  // no collaterals or positions
  const isEmpty = !subaccount.collaterals.length && !subaccount.positions.length

  const usdcAvailableToWithdraw = bigNumberToNumberUNSAFE(
    collateralBalances[CollateralId.USDC].transferrableBalanceBn,
    WEI_DECIMALS
  )

  const usdcCollateral = getSubaccountCollateral(subaccount, CollateralId.USDC)
  const usdcBorrowed = usdcCollateral ? Math.abs(Math.min(+usdcCollateral.amount, 0)) : 0
  const usdcBalance = usdcCollateral ? Math.max(+usdcCollateral.amount, 0) : 0

  const usdcAvailableToBorrow = Math.max(usdcAvailableToWithdraw - usdcBalance, 0)

  return {
    subaccount,
    collateralValue,
    buyingPower,
    marginUtilization,
    subaccountValue,
    collateralBalances,
    usdcBalance,
    usdcAvailableToBorrow,
    usdcBorrowed,
    usdcAvailableToWithdraw,
    label,
    isEmpty,
  }
}

export const formatSubaccountDefaultLabel = (accountNumber: number): string => {
  return `Account ${accountNumber}`
}

export const formatMarginTypeLabel = (marginType: MarginType): string => {
  switch (marginType) {
    case 'SM':
      return 'Standard Margin'
    case 'PM':
      return 'Portfolio Margin'
  }
}

export const getSubaccountCollateral = (
  subaccount: Subaccount,
  collateral: CollateralId
): Subaccount['collaterals'][0] | undefined => {
  return subaccount.collaterals.find(
    (collat) => collat.asset_name.toLowerCase() === collateral.toLowerCase()
  )
}

export const updateSubaccountLabel = async (
  subaccountId: number,
  label: string,
  authHeaders: LyraAuthHeaders
) => {
  await changeSubaccountLabel(
    {
      subaccount_id: subaccountId,
      label: label,
    },
    authHeaders
  )
}

export const getTransferInput = (
  fromSubaccountId: TransferSubaccountId,
  toSubaccountId: TransferSubaccountId | 'create'
): TransferInput => {
  if (fromSubaccountId === 'funding' && typeof toSubaccountId === 'number') {
    return {
      type: 'deposit',
      fromSubaccountId,
      toSubaccountId,
    }
  } else if (typeof fromSubaccountId === 'number' && typeof toSubaccountId === 'number') {
    return {
      type: 'transfer',
      fromSubaccountId,
      toSubaccountId,
    }
  } else if (typeof fromSubaccountId === 'number' && toSubaccountId === 'funding') {
    return {
      type: 'withdraw',
      fromSubaccountId,
      toSubaccountId,
    }
  }
  return {
    type: 'create',
    fromSubaccountId: 'funding',
    toSubaccountId: null,
  }
}

export const getTransferLoggerAction = (transferType: TransferType): LoggerTransaction => {
  switch (transferType) {
    case 'deposit':
    case 'create':
      return 'subaccount-deposit'
    case 'transfer':
      return 'transfer'
    case 'withdraw':
      return 'subaccount-withdraw'
  }
}
